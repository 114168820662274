<template>
   <div>
      <div class="my-6 text-start">
    <h3 class="font-bold">Criar um novo pagamento</h3>
    <span class="text-sm text-gray-500"
      >Preencha as informações abaixo e clique no botão <b>criar</b> para salvar
      as alterações.
    </span>
  </div>

  <div class="card shadow-sm border p-8 bg-white">
    <div class="flex lg:flex-row flex-col mt-2">
      <div class="w-full p-2">
        <Input
          type="datalist"
          id="tipo"
          v-model="data.tipo_produto"
          label="Tipos"
          :data="tipos"
          placeholder="Selecione um Tipo de Produto"
        />
      </div>
      <div class="w-full p-2">
        <Input
          type="default"
          v-model="data.nome"
          label="Nome"
          placeholder="Nome"
        />
      </div>
    </div>
    <div class="flex justify-end mt-5">
    <Button @click="create" color="primary" text="Criar"></Button>
    </div>
  </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, ref ,onMounted} from "vue";
import { GET, POST } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRouter } from "vue-router";
 
export default {
  name: "CriarSubTipos",
  components: {
    Input,
    Button,
  },
  data()
  {
    return{
      isMobile: false
    }
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  setup() {
    const data = ref({
    "tipo_produto": "",
    "nome": ""
    });

    const tipos = ref([]);

    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();


    onMounted(async () => {
      loader.open();

      try {
        const tipoRes = await GET("tipo_produto");
        
        tipoRes.forEach((tipo) =>
          tipos.value.push({ label: tipo.nome, value: tipo.id })
        );


      } catch (e) {
        alert.open("Erro", e, "danger");
      }

      loader.close();
    }) 


    const create = async () => {
      loader.open();
      const obj = ref({});

      obj.value = {... data.value}

      



      const [tipoSelected] = tipos.value.filter(tipo => tipo.label ==  data.value.tipo_produto)


      if(tipoSelected)
      obj.value["id_tipo_produto"] = tipoSelected.value;


      const validate = isEmpty(obj.value);
      

      if (validate) {
        alert.open("Atenção!", validate, "warning");

      } else {
        try {
          delete obj.value.tipo_produto
          
          await POST("subtipo_produto", obj.value);
          alert.open(
            "Sucesso!",
            `Sub Tipo criado com sucesso!`,
            "success"
          );
          router.push("/subtipoproduto");
        } catch (e) {
          alert.open("Atenção!", e, "danger");
        }
      }
      loader.close();
    };
    

    return {
      data,
      create,
      tipos
    };
  },
};
</script>

<style>
</style>